import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { darken } from 'color2k';
import { FieldError } from 'react-hook-form';
import { ifNotProp, ifProp } from 'styled-tools';
import { useInputField } from '$hooks';
import { StyledHelpText, StyledInvalidMessage } from '../input-field/style';
import { SvgIcon } from '../svg-icon';
import { cover } from '$lib/style-helpers';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type CheckboxProps = Omit<InputProps, 'placeholder' | 'value'> & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string | React.ReactNode;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Errors from react-hook-form
     */
    error?: FieldError;

    /**
     * Toggle between checkbox or radio
     */
    type?: 'checkbox' | 'radio';

    /**
     * Vertical alignment in container
     */
    verticalAlignment?: 'flex-start' | 'flex-end' | 'center';
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    (
        {
            label,
            helpText,
            error,
            id,
            onChange,
            className,
            type = 'checkbox',
            verticalAlignment,
            ...rest
        }: CheckboxProps,
        ref
    ) => {
        const [isValidityValid, setIsValidityValid] = useState(true);
        const [isChecked, setIsChecked] = useState(!!rest.defaultChecked);
        const inputRef = useRef<HTMLInputElement>(null);

        const theme = useTheme();

        const { fieldId, helpTextId, describedById, showHelpText, invalidMessageId } = useInputField({
            id,
            helpText,
        });

        const onBlueHandler = () => {
            setIsValidityValid(inputRef.current?.validity.valid ? true : false);
        };

        const isValid = !error && isValidityValid;

        useEffect(() => {
            !!rest.checked && setIsChecked(true);
        }, [rest.checked]);

        const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            setIsChecked(event.target.checked);
            onChange && onChange(event);
        };

        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, []);

        return (
            <div key={fieldId} className={className}>
                <StyledCheckboxWrapper
                    valid={isValid}
                    onBlur={onBlueHandler}
                    isDisabled={rest.disabled}
                    verticalAlignment={verticalAlignment}
                >
                    <StyledCheckboxElement
                        round={type === 'radio'}
                        isChecked={rest.checked || (type === 'checkbox' && isChecked)}
                        disabled={rest.disabled || false}
                    >
                        <StyledCheckbox
                            type={type}
                            id={fieldId}
                            ref={inputRef}
                            aria-describedby={describedById}
                            onChange={onChangeHandler}
                            {...rest}
                        />
                        <StyledCheckboxIndicator round={type === 'radio'}>
                            {(rest.checked || (type === 'checkbox' && isChecked)) && (
                                <>
                                    {type === 'radio' ? (
                                        <StyledCheckboxDot />
                                    ) : (
                                        <SvgIcon svg="checkmark" color={theme.colors.white} size={11} />
                                    )}
                                </>
                            )}
                        </StyledCheckboxIndicator>
                    </StyledCheckboxElement>
                    {!!label && (
                        <StyledLabel verticalAlignment={verticalAlignment} htmlFor={fieldId}>
                            {label}
                        </StyledLabel>
                    )}
                </StyledCheckboxWrapper>
                <StyledCheckboxHelpTexts>
                    {error && <StyledInvalidMessage id={invalidMessageId}>{error.message}</StyledInvalidMessage>}
                    {showHelpText && <StyledHelpText id={helpTextId}>{helpText}</StyledHelpText>}
                </StyledCheckboxHelpTexts>
            </div>
        );
    }
);

const StyledCheckbox = styled.input({
    top: 0,
    left: 0,
    width: '100%',
    cursor: 'inherit',
    height: '100%',
    margin: 0,
    opacity: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
});

const StyledLabel = styled.label<{ verticalAlignment?: 'flex-start' | 'flex-end' | 'center' }>(
    ({ theme, verticalAlignment }) => ({
        color: theme.colors.black,
        fontSize: theme.fontSizes.sm,
        display: 'block',
        cursor: 'pointer',
        padding: `1px 0 0 ${theme.space[3]}`,
        lineHeight: '18px',
        minHeight: 19,
        ...(verticalAlignment && {
            display: 'flex',
            alignItems: verticalAlignment,
        }),
    })
);

const StyledCheckboxIndicator = styled.div<{ round: boolean }>(({ round }) => ({
    ...cover,
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: `1px solid transparent`,
    borderRadius: round ? '2px' : '0',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
}));

const StyledCheckboxElement = styled.div<{ round: boolean; isChecked: boolean; disabled: boolean }>(
    ({ theme }) => ({
        position: 'relative',
        border: `1px solid ${theme.colors.black}`,
        borderRadius: '2px',
        boxSizing: 'border-box',
        width: 18,
        height: 18,
        cursor: 'pointer',
    }),
    ifProp('round', {
        borderRadius: '100%',
        [`${StyledCheckboxIndicator}`]: {
            borderRadius: '100%',
            margin: '-1px',
            padding: '1px',
        },
    }),
    ifProp('isChecked', ({ theme }) => ({
        borderColor: theme.colors.primary,
    })),
    ifProp('disabled', ({ theme }) => ({
        borderColor: theme.colors.grey30,
    }))
);

const StyledCheckboxWrapper = styled.div<{
    valid: boolean;
    isDisabled?: boolean;
    verticalAlignment?: 'flex-start' | 'flex-end' | 'center';
}>(
    ({ theme, verticalAlignment }) => ({
        display: 'grid',
        gridTemplateColumns: `19px 1fr`,
        ...(verticalAlignment && {
            alignItems: verticalAlignment,
        }),
        ':hover, :focus-within': {
            [`${StyledCheckboxIndicator}`]: {
                backgroundColor: theme.colors.grey15,
            },
            [`input:checked + ${StyledCheckboxIndicator}`]: {
                backgroundColor: darken(theme.colors.primary, 0.05),
            },
        },

        [`input:checked + ${StyledCheckboxIndicator}`]: {
            backgroundColor: theme.colors.primary,
        },
    }),
    ifNotProp('valid', ({ theme }) => ({
        [`${StyledLabel}`]: {
            color: theme.colors.negative,
        },
    })),
    ifProp(
        { isDisabled: true },
        {
            opacity: 0.7,
            ':hover, :focus-within': {
                [`${StyledCheckboxIndicator}`]: {
                    backgroundColor: 'transparent',
                },
            },
        }
    )
);

const StyledCheckboxHelpTexts = styled.div(({ theme }) => ({
    marginLeft: `calc(19px + ${theme.space[3]})`,
}));

const StyledCheckboxDot = styled.div(({ theme }) => ({
    width: '8px',
    height: '8px',
    backgroundColor: theme.colors.white,
    borderRadius: theme.general.circleRadius,
}));
